<!--
 * @Description: 关于我们页面组件，未完成
 -->
<template>
    <div class="about detail-box" id="about" name="about">
        <div class="daohang">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/productContect' }">
                    产品中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{
                    path: '/productContectChilds?pid=' + typeid + '&name=' + name,
                }" v-if="typeid == 1">{{ name }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{
                    path:
                        '/productType?pid=' +
                        typeid +
                        '&id=' +
                        categoryId +
                        '&name=' +
                        name +
                        '&childname=' +
                        childname,
                }" v-if="typeid == 1">{{ childname }}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color: #ce3720">产品详情</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div :class="[equipment ? 'header-img' : 'header-imgs']">
            <img :src="$target + categoryFind.banner_image" fit="calc" />
            <!-- <div class="tit">{{categoryFind.name}}</div> -->
        </div>
        <div class="about-header" v-for="(item, index) in tableData" :key="index">
            <div class="header-box">
                <div>
                    <div class="title">{{ item.goods_name }}</div>
                    <div class="left-box">
                        <el-image class="left-img" :src="$target + item.image" v-image-preview></el-image>
                        <div class="li-lab" v-html="convertPtToPx(item.subtitle)"></div>
                    </div>
                </div>
                <div class="right-img">
                    <!-- <img :src="$target + item.image" alt=""> -->
                    <el-image v-if="item.image2_hz == 1" class="img" :src="$target + item.image2" fit="contain"
                        v-image-preview></el-image>
                    <Video v-if="item.image2_hz == 2" :src="$target + item.image2" :width="300" :height="200"
                        :second="3" class="img" />
                </div>
            </div>
            <div class="miaosu" v-html="convertPtToPx(item.content)"></div>
            <div class="product-list">
                <div class="product-tit">
                    <div class="product-tit-col1">产品型号</div>
                    <div class="product-tit-col2">产品详情</div>
                    <div class="product-tit-col3">含税价格</div>
                    <div class="product-tit-col4">发货日期</div>
                    <div class="product-tit-col5">Buy</div>
                </div>
                <div class="product-li" v-for="item1 in item.spec" :key="item1.id">
                    <div class="product-li-cont">
                        <div class="product-li-col1">
                            <div class="info-btn" @click="toggetBtn(item, item1)" v-show="item1.isShow">
                                折叠 -
                            </div>
                            <div class="info-btn" @click="toggetBtn(item, item1)" v-show="!item1.isShow">
                                展开 +
                            </div>
                            <div class="code">{{ item1.number }}</div>
                        </div>
                        <div class="product-li-col2">
                            {{ item1.content }}
                        </div>
                        <div class="product-li-col3">RMB {{ item1.price }}</div>
                        <div class="product-li-col4">
                            <div class="product-li-lab-xianhuo" v-if="item1.shipmen_tstatus == 1">
                                <img src="../../assets/index/dui.png" alt="" />
                                {{ item1.shipmen_tstatus_text }}
                            </div>
                            <div class="product-li-lab" v-else>
                                <img src="../../assets/index/dui-bai.png" alt="" />
                                {{ item1.shipmen_tstatus_text }}
                            </div>
                        </div>
                        <div class="product-li-col5">
                            <div class="product-num">
                                <div class="num">
                                    <input class="num-input" type="text" v-model="item1.num" />
                                </div>
                                <div class="shop-car" @click="getAddCar(item1)">
                                    <img src="../../assets/index/shop-car.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-info-box" :class="item1.isShow ? '' : 'zhedie'">
                        <div class="product-info-head">
                            <div class="product-info-head-img">
                                <img :src="$target + item1.images" alt="" v-image-preview />
                            </div>
                            <div class="product-info-head-r">
                                <div class="product-info-head-no">{{ item1.name }}</div>
                                <div class="bianma">型号：{{ item1.number }}</div>
                                <div class="wendang-box">
                                    <div class="wendang-li" v-for="(item2, index) in item1.annex_arr" :key="index">
                                        <!-- <img src="../../assets/index/pdf.png" alt=""> -->
                                        <span @click="yulan(item2.url)">{{ item2.name }}</span>
                                    </div>
                                    <!-- <div class="wendang-li">
                                        <img src="../../assets/index/code.png" alt="">
                                        <span>STEP.stp</span>
                                    </div>
                                    <div class="wendang-li">
                                        <img src="../../assets/index/eprt.png" alt="">
                                        <span>STEP.stp</span>
                                    </div>
                                    <div class="wendang-li">
                                        <img src="../../assets/index/igs.png" alt="">
                                        <span>基本图形转换规范：igs</span>
                                    </div>
                                    <div class="wendang-li">
                                        <img src="../../assets/index/step.png" alt="">
                                        <span>STEP.stp</span>
                                    </div>
                                    <div class="wendang-li">
                                        <img src="../../assets/index/zar.png" alt="">
                                        <span>STEP.stp</span>
                                    </div>
                                    <div class="wendang-li">
                                        <img src="../../assets/index/xsl.png" alt="">
                                        <span>STEP.stp</span>
                                    </div> -->
                                    <div class="wendang-li" @click="downloadFiles(item1.annex_arr)">
                                        <img src="../../assets/index/all.png" alt="" />
                                        <span>下载全部资料</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table">
                            <div class="table-row" v-for="(value, key) in item1.josn_arr" :key="key">
                                <div class="table-col1">{{ key }}：</div>
                                <div class="table-col2">{{ value }}</div>
                            </div>
                            <div class="table-row" :style="Object.keys(item1.josn_arr).length % 2 !== 0
                                ? ''
                                : 'display:none;'
                                "></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h100"></div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Video from "../../components/Video";
import {
    categoryFindApi,
    goodsApi,
    addCarApi,
    myCarApi,
    goodsSearchApi,
    goodsInfoApi,
} from "../../api/index";
export default {
    components: {
        Video,
    },
    data() {
        return {
            equipment: true,
            categoryId: "",
            name: "",
            typeid: "",
            childname: "",
            isShow: 1,
            productNum: 1,
            categoryFind: {},
            tableData: [],
            src: "https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/Bao.mp4",
        };
    },
    created() {
        this.init()
    },
    activated() {
        console.log("this.$route.query.typeid", this.$route.query.typeid);
        this.name = this.$route.query.name;
        this.typeid = this.$route.query.typeid;
        this.childname = this.$route.query.childname;
        this.categoryId = this.$route.query.id;
        this.getCategoryFind();
        this.getGoods();
    },
    methods: {
        // 判断手机还是pc
        init() {
            const sUserAgent = navigator.userAgent.toLowerCase();
            /* eslint-disable */
            const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
            const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
            const bIsMidp = sUserAgent.match(/midp/i) == "midp";
            const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
            const bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
            const bIsAndroid = sUserAgent.match(/android/i) == "android";
            const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
            const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

            if (
                bIsIpad ||
                bIsIphoneOs ||
                bIsMidp ||
                bIsUc7 ||
                bIsUc ||
                bIsAndroid ||
                bIsCE ||
                bIsWM
            ) {
                this.equipment = false;
            } else {
                this.equipment = true;
            }
            console.log(this.equipment);
        },
        ...mapActions(["setShoppingCart"]),
        // 展示、折叠
        toggetBtn(i, j) {
            let list = this.tableData;
            list.map((item) => {
                if (item.id == i.id) {
                    item.spec.map((item1) => {
                        if (item1.id == j.id) {
                            item1.isShow = !j.isShow;
                        }
                    });
                }
            });
        },
        getCategoryFind() {
            categoryFindApi({ id: this.categoryId }).then((res) => {
                this.categoryFind = res.data;
            });
        },
        downloadFiles(arr) {
            arr.map((item) => {
                this.downloadFile(item.url, item.name);
            });
        },
        async downloadFile(url, name) {
            let response = await fetch(this.$target + url);
            // 内容转变成blob地址
            let blob = await response.blob();
            // 创建隐藏的可下载链接
            let objectUrl = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.style.display = "none";
            //地址
            a.href = objectUrl;
            //修改文件名
            a.download = name;
            // 触发点击
            document.body.appendChild(a);
            a.click();
            //移除
            URL.revokeObjectURL(a.href);
        },
        yulan(url) {
            window.open(
                "http://xptlab.com" + url

            );
        },
        getAddCar(e) {
            let data = {
                spec_id: e.id,
                num: e.num,
            };
            addCarApi(data).then((res) => {
                console.log(res);
                this.$message.success("添加成功");
                myCarApi().then((res1) => {
                    this.setShoppingCart(res1.data);
                });
            });
        },
        getGoods() {

            let data = {
                category_id: this.categoryId,
                limit: 999,
                goods_name: "",
            };
            if (this.typeid == 1 || this.typeid == 4) {
                goodsApi(data).then((res) => {
                    let list = res.data.data;
                    list.map((item) => {
                        item.spec.map((item1) => {
                            item1.isShow = false;
                            item1.num = 1;
                        });
                    });
                    console.log("获取数据111", list);
                    this.tableData = list;
                });
            } else if (this.typeid == 2) {
                goodsSearchApi(data).then((res) => {
                    let list = res.data.data;
                    list.map((item) => {
                        item.spec.map((item1) => {
                            item1.isShow = false;
                            item1.num = 1;
                        });
                    });
                    this.tableData = list;
                    console.log("goodsSearchApi", this.tableData);
                });
            } else {
                let data3 = {
                    goods_id: this.categoryId,
                    limit: 999,
                };
                goodsInfoApi(data3).then((res) => {
                    let list = [res.data];
                    list.map((item) => {
                        item.spec.map((item1) => {
                            item1.isShow = false;
                            item1.num = 1;
                            // if (item1.annex) {
                            //     item1.annex_arr = item1.annex.split[',']
                            // }
                        });
                    });
                    this.tableData = list;
                    console.log("goodsInfoApi", this.tableData);
                });
            }
        },
        convertPtToPx(htmlContent) {
            // 使用正则表达式匹配 pt 单位
            const regex = /(\d*\.?\d+)pt/g;

            // 使用 replace 方法替换匹配到的 pt 值
            const convertedContent = htmlContent.replace(
                regex,
                function (match, ptValue) {
                    // 将 pt 值转换为 px 值，这里使用 1pt ≈ 1.333px
                    const pxValue = parseFloat(ptValue) * 0.3528;
                    // 返回转换后的 px 值，并保留小数点后两位
                    return pxValue.toFixed(2) + "px";
                }
            );
            if (window.innerWidth <= 750) {
                return convertedContent;
            } else {
                return htmlContent;
            }
        },
    },
};
</script>
<style scoped>
.h100 {
    height: 100px;
}

.miaosu {
    padding-top: 10px;
    font-size: 12px !important;
    font-family: "惠普字体Medium";
}

.miaosu p {
    font-size: 12px !important;
    font-family: "惠普字体Medium";
}

.miaosu span {
    font-size: 12px !important;
    font-family: "惠普字体Medium";
}

.about {
    width: 1250px;
    margin: 0 auto;
}

.daohang {
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 18px;
    width: 1250px;
    margin-top: 40px;
}

.about .about-header {
    min-width: 1200px;
    margin: 0 auto 20px;
    padding: 20px 22px 20px;
    border: 5px solid #c2c2c2;
    background-color: #f3f3f3;
}

.about .about-header .pdf {
    width: 1250px;
    min-height: 4135px;
    background: #ffffff;
    border-radius: 14px 14px 14px 14px;
}

.header-img {
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}

.header-img img {
    margin-bottom: 29px;
    width: 100%;
    height: 100%;
}

.header-imgs {
    margin: 0 auto;
    width: 1250px;
    height: 400px;
    margin-bottom: 20px;
}

.header-imgs img {
    margin-bottom: 29px;
    width: 100%;
    height: 100%;
}

.tit {
    position: absolute;
    top: 68px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 84px;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    text-align: center;
    letter-spacing: 2px;
}

.title {
    padding-bottom: 16px;
    height: 31px;
    font-size: 20px;
    font-family: "惠普字体Bold";
    color: #bb0200;
}

.li-lab {
    padding-bottom: 11px;
    line-height: 20px;
    font-size: 12px;
    font-family: "惠普字体Medium";
}

.header-box {
    display: flex;
    justify-content: space-between;
}

.right-img {
    margin-top: 50px;
    width: 300px;
    height: 200px;
    background: linear-gradient(180deg, #ededed 0%, #fcfcfc 54%, #ececec 100%);
}

.right-img .img {
    width: 100%;
    height: 100%;
}

.dian {
    margin-right: 17px;
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    background: #bb0200;
    border-radius: 2px;
}

.mioasu {
    font-size: 12px;
}

.product-list {
    padding-top: 20px;
}

.product-tit {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 9px solid #c2c2c2;
    font-family: "惠普字体Medium";
    font-size: 14px;
}

.product-tit-col1 {
    width: 20%;
    text-align: center;
}

.product-tit-col2 {
    width: 40%;
}

.product-tit-col3 {
    width: 20%;
    text-align: center;
}

.product-tit-col4 {
    width: 20%;
    text-align: center;
}

.product-tit-col5 {
    width: 10%;
    text-align: center;
}

.product-li {
    /* height: 60px; */
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 7px;
    line-height: 45px;
    border-bottom: 2px solid #d6d6d6;
}

.product-li-cont {
    display: flex;
}

.product-li-col1 {
    width: 20%;
    display: flex;
}

.product-li-col2 {
    width: 500px;
    font-family: "惠普字体Medium";
    font-size: 12px;
}

.product-li-col3 {
    width: 20%;
    font-family: "惠普字体Medium";
    font-size: 14px;
    color: #000000;
    text-align: center;
}

.product-li-col4 {
    width: 20%;
    text-align: center;
    line-height: 36px;
}

.product-li-col5 {
    width: 10%;
    text-align: center;
    line-height: 36px;
    position: relative;
}

.info-btn {
    width: 38px;
    margin-top: 7px;
    height: 30px;
    padding: 0 10px;
    background: #2b60bc;
    line-height: 30px;
    border-radius: 6px;
    text-align: center;
    /* line-height: 30px; */
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    font-family: "惠普字体Medium";
}

.code {
    margin-left: 20px;
    font-family: "惠普字体Medium";
    font-size: 12px;
    color: #1955b8;
    text-align: center;
    cursor: pointer;
}

.product-li-lab {
    margin-left: 58px;
    margin-top: 10px;
    width: 81px;
    height: 23px;
    line-height: 23px;
    background: #020c00;
    color: #ffffff;
    font-family: "惠普字体Medium";
    font-size: 12px;
}

.product-li-lab-xianhuo {
    margin-left: 40px;
    width: 5.875vw;
    font-size: 12px;
    color: #020c00;
}

.product-li-lab-xianhuo img {
    width: 0.825vw;
    height: 0.6625vw;
    vertical-align: middle;
}

.product-li-lab img {
    width: 0.625vw;
    height: 0.5625vw;
    vertical-align: middle;
}

.product-num {
    /* margin-top: 6px;     */
    display: flex;
    /* margin-left: 75px; */
    position: absolute;
    right: 0;
    top: 6px;
    width: 74px;
    height: 28px;
    border: 2px solid #ce3720;
    justify-content: space-between;
}

.product-num .num {
    width: 40px;
    height: 28px;
}

.num-input {
    margin-top: 0px;
    padding-left: 7px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    vertical-align: top;
    font-size: 14px;
}

.shop-car {
    width: 28px;
    height: 28px;
    background: #ce3720;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-car img {
    /* margin-top: 7px; */
    width: 20px;
    height: 20px;
    vertical-align: top;
}

.product-info-box {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    transform: scaleY(1);
    transform-origin: center top;
    overflow: hidden;
    box-sizing: border-box;
}

.product-info-head {
    padding-top: 20px;
    display: flex;
}

.product-info-head-img {
    width: 130px;
    height: 100px;
    margin-right: 25px;
}

.product-info-head-img img {
    width: 100%;
    height: 100%;
}

.product-info-head-r {}

.product-info-head-no {
    font-size: 20px;
    color: #1955b8;
}

.bianma {
    font-size: 14px;
    color: #000000;
}

.wendang-box {
    display: flex;
    flex-wrap: wrap;
}

.wendang-li {
    margin-right: 12px;
    cursor: pointer;
}

.wendang-li img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.wendang-li span {
    vertical-align: middle;
    margin-left: 4px;
    line-height: 35px;
    font-size: 12px;
}

.table {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 20px solid #efefef;
    font-size: 1.5vw !important;
}

.table-row {
    width: 50%;
    display: flex;
    border-top: 20px solid #efefef;
    font-family: "惠普字体Medium";
}

.table-col1 {
    width: 25%;
    padding-left: 10px;
    font-size: 14px;
}

.table-col2 {
    width: 60%;
    padding-left: 10px;
    font-size: 12px;
}

.table-col3 {
    width: 35%;
    padding-left: 10px;
    font-size: 14px;
}

.table-col4 {
    width: 15%;
    padding-left: 10px;
    font-size: 12px;
}

.zhedie {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    max-height: 0;
}

.left-box {
    display: flex;
}

.left-img {
    margin-right: 20px;
    width: 300px;
    height: 200px;
}
</style>